import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import theme from "./AppTheme";
import StylesProvider from "@material-ui/styles/StylesProvider";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createGenerateClassName from "@material-ui/styles/createGenerateClassName";

import "./assets/css/main.scss"

let element = document.getElementById('ew-widgets');
console.log("Element: ", element);
element && element.classList.add("ew-widgets");

if (element) {
    const ChatApp = lazy(() => import('./ChatApp'));
    const PopupApp = lazy(() => import('./PopupApp'));
    let client_id = element.getAttribute("data-client-id");
    ReactDOM.render(
        <>
            <ThemeProvider theme={theme}>
                <StylesProvider generateClassName={createGenerateClassName({
                    seed: 'ew-wg-',
                    disableGlobal: true,
                    productionPrefix: "ew-wig"
                })}>
                    <Suspense fallback={null}>
                        <PopupApp client_id={client_id}/>
                    </Suspense>
                    <Suspense fallback={null}>
                        <ChatApp client_id={client_id}/>
                    </Suspense>
                </StylesProvider>
            </ThemeProvider>
        </>,
        element
    );
}

function getPresetElementId() {
    let a = ["shopify-section-product-template", "shopify-section-static-product", "shopify-section-product"]
    for (let i = 0; i < a.length; i++) {
        if (document.getElementById(a[i])) {
            return a[i];
        }
    }
    return null;
}

function getKnowThemeSelector(): HTMLElement | null {
    let default_element = document.getElementById("ew-product-review-section");
    if (default_element) {
        return default_element
    }
    let ele = document.createElement('div');
    ele.id = "ew-product-review-section";
    ele.style.display = "inherit";

    let possible_selector = getPresetElementId();
    if (possible_selector) {
        let product_section = document.getElementById(possible_selector);
        if (product_section) {
            let parent = product_section.parentNode;
            if (parent) {
                parent.insertBefore(ele, product_section.nextSibling);
                return ele;
            }
        }
    }
    let a = document.querySelectorAll('[id^="shopify-section-template--"][id$="__main"]')


    if (a && a.length > 0) {
        let parent = a.item(0).parentNode;
        if (parent) {
            parent.insertBefore(ele, a.item(0).nextSibling);
            return ele;
        }
    }
    return null
}


function ShowAllProductReview() {
    const AllProductReviewApp = lazy(() => import('./AllProductReviewApp'));
    let all_product_elements = document.getElementById('ew-all-products-reviews')
    if (all_product_elements && element) {
        let client_id = element.getAttribute("data-client-id");
        ReactDOM.render(
            <Suspense fallback={null}>
                <AllProductReviewApp
                    client_id={client_id}
                />
            </Suspense>,
            all_product_elements
        );

    }
}

ShowAllProductReview();

if (window.hasOwnProperty("meta")) {
    // @ts-ignore
    let product: any = window.meta.product;
    if (product) {
        if (element) {
            const ProductReviewApp = lazy(() => import('./ProductReviewApp'));
            let selector = getKnowThemeSelector();
            if (selector) {
                let client_id = element.getAttribute("data-client-id");
                // @ts-ignore
                let shop_name = window.Shopify && window.Shopify.shop;
                let product_id = product.id;
                if (shop_name) {
                    ReactDOM.render(
                        <Suspense fallback={null}>
                            <ProductReviewApp client_id={client_id} shop_name={shop_name} product_id={product_id}/>
                        </Suspense>,
                        selector
                    );
                }

            }

        }

    }

}


if (element && element.getAttribute("data-client-id") && element.getAttribute("data-product-id")) {
    const ProductReviewApp = lazy(() => import('./ProductReviewApp'));
    let selector = document.getElementById("ew-product-review");
    if (selector) {
        let client_id = element.getAttribute("data-client-id");
        // @ts-ignore
        let shop_name = "emailwish-tester-store-1.myshopify.com";
        let product_id = element.getAttribute("data-product-id");
        if (shop_name) {
            ReactDOM.render(
                <Suspense fallback={null}>
                    <ProductReviewApp client_id={client_id} shop_name={shop_name} product_id={product_id}/>
                </Suspense>,
                selector
            );
        }

    }
}
